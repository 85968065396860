import { Injectable } from '@angular/core';
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest
} from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { User } from 'src/app/core/interfaces/enums';
import { LocalStorageService } from './localStorage.service';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this.handleAuthentication(req)).pipe(
      mergeMap((authRequest) => next.handle(authRequest)),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            localStorage.clear();
            this.router.navigateByUrl('auth');
          } else {
            if (!error.ok) {
              console.error('Запрос не прошел');
            }
          }
        }
        return throwError(error);
      })
    );
  }

  private async handleAuthentication(
    request: HttpRequest<any>
  ): Promise<HttpRequest<any>> {
    const token = await this.localStorageService.getUserToken();
    if (token) {
      return request.clone({
        headers: request.headers.set('Authorization', token).set('Client', 'browser'),
      });
    }

    if (request.url.includes('guest/token') || request.url.includes('signin')) {
      return request.clone({
        headers: request.headers.set('Client', 'browser'),
      });
    }

    const guestToken = await this.localStorageService.getItemLocalStorage<string>(
      User.GUEST_DATA
    );
    if (guestToken) {
      return request.clone({
        headers: request.headers.set('Authorization', guestToken).set('Client', 'browser'),
      });
    }

    return request.clone({
      headers: request.headers.set('Client', 'browser'),
    });
  }
}