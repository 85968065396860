import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/AuthGuard';
import { ProfileResolver } from './core/resolvers/profile.resolver';
import { ErrorComponent } from './pages/error/error.component';

const routes: Routes = [
	{
		path: 'profile',
		loadChildren: () =>
			import('./pages/profile/profile.module').then(
				(m) => m.ProfileModule
			),
		canActivate: [AuthGuard],
		resolve: { userData: ProfileResolver },
	},
	{
		path: '',
		loadChildren: () =>
			import('./pages/home/home.module').then((m) => m.HomePageModule),
	},
	{
		path: 'event',
		loadChildren: () =>
			import('./pages/event/event.module').then((m) => m.EventModule),
	},
	{
		path: 'auth',
		loadChildren: () =>
			import('./pages/auth/auth.module').then((m) => m.AuthModule),
	},
	{
		path: 'terms',
		loadChildren: () =>
			import('./pages/terms/terms.module').then((m) => m.TermsModule),
	},
	{
		path: 'prices',
		loadChildren: () =>
			import('./pages/prices/prices.module').then((m) => m.PricesModule),
	},
	{
		path: 'payment/confirm',
		loadChildren: () =>
			import('./pages/payment/payment.module').then((m) => m.PaymentModule),
		canActivate: [AuthGuard],
	},
	{
		path: '**',
		pathMatch: 'full',
		component: ErrorComponent,
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
		preloadingStrategy: PreloadAllModules
	})],
	exports: [RouterModule],
})
export class AppRoutingModule {}
