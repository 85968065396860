import { inject } from '@angular/core';
import { RedirectCommand, ResolveFn, Router } from '@angular/router';
import { LocalStorageService } from '../services/localStorage.service';
import { User } from '../interfaces/enums';
import { IUserStorageData } from '../interfaces/users';

export const ProfileResolver: ResolveFn<IUserStorageData | RedirectCommand> = async () => {
  const localStorageService = inject(LocalStorageService);
  const router = inject(Router);

  try {
    const data = await localStorageService.getItemLocalStorage<IUserStorageData>(User.USER_DATA);

    if (!data) {
      return new RedirectCommand(router.parseUrl(''));
    }

    return data;
  } catch (error) {
    console.error('Error in ProfileResolver:', error);
    return new RedirectCommand(router.parseUrl('')); // Обработка ошибки и перенаправление
  }
};
